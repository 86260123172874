import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    name: '',
    password: '',
		activeIndex: 0,
    interval: 72000,
    flag: false,
		num: 0,
    code: 0
  },
  mutations: {
    set_token(state, token) {
      state.token = token
      localStorage.token = token
    },
    set_name(state, userName) {
      state.name = userName
      localStorage.name = userName
    },
    set_pass(state, pass) {
      state.password = pass
      localStorage.password = pass
    },
    del_token(state) {
      state.token = ''
      localStorage.removeItem('token')
    },
    del_name(state) {
      state.name = ''
      localStorage.removeItem('name')
    },
    del_user(state) {
      state.password = ''
      localStorage.removeItem('password')
    }
  },
  actions: {

  }
})
