/* eslint-disable no-unused-vars */
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import * as echarts from "echarts"
import "echarts-gl"
import scroll from 'vue-seamless-scroll'
import { Progress, Carousel, CarouselItem, Dialog } from 'element-ui';

import './util/rem'

import $ from 'jquery'
import animated from 'animate.css'
import axios from 'axios'
axios.defaults.withCredentials = true// 让ajax携带cookie
import { Input, Message, MessageBox, Select, Option } from 'element-ui'
Vue.use(Progress);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Input)
Vue.use(animated)
Vue.use(Select)
Vue.use(Option)
Vue.use(scroll)
Vue.use(Dialog);

Vue.prototype.$http = axios
Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox

Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false
/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
