<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
export default {
  name: 'App',
  provide() {
    return {
			
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  methods: {
		
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    }
  }

}
</script>
<style lang="scss">
*{
  margin: 0;
  padding: 0;
  list-style: none;	
}

#app {
  width: 100%;
  height: 100%;
  background: url('./assets/image/bj@2x.png') center top no-repeat;
  background-size: 100% 100%;
	overflow-x: hidden;
}
::-webkit-scrollbar {
		display: none !important;
		/* Chrome */
	}
// /deep/.el-select-dropdown {
//   position: absolute;
//   z-index: 1001;
//   border: 0.0625rem solid #414bb9;
//   border-radius: 0.25rem;
//   background-color: #414bb9;
//   -webkit-box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
//   box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
//   margin: 0.3125rem 0;
// }
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.clearfix:after {
  content: ".";
  clear: both;
  height: 0;
  visibility: hidden;
  display: block;
}

.clearfix {
  zoom: 1;
}

	.container {
		
	}


</style>
