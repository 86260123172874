/* eslint-disable no-unused-vars */
import Vue from 'vue'
import store from '@/store/store'
import Router from 'vue-router'

Vue.use(Router)


const router = new Router({
  /* mode: 'history',//去掉#*/
  routes: [
    {
      path: '/',
      name: 'index',
      redirect: '/index'
    },
    {
		  path: '/index',
		  name: 'index',
		  component: () => import('@/views/index')
		},
  ]
})

export default router
